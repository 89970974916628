<template>
  <div class="addTypesetting">
    <el-dialog
        :title='$t("Scheduling")'
        width="80%"
        style="marginTop:50px;"
        :append-to-body="true"
        :visible.sync="dialogVisible"
        :before-close="handleClose">
      <div class="">
        <div
            style="display:flex;justify-content: space-between;font-size: 24px;font-weight: 500;padding: 0 50px;margin-bottom: 20px;">
          <div class="">
            <p style="color:#009eff;">{{ workshopName }}</p>
          </div>
          <div>
            <p>{{ ruleForm.dateTime }}</p>
          </div>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          <!--          <el-form-item label="当前车间：" prop="workshop">
                      <el-input v-model="ruleForm.workshop" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="日期：" prop="dateTime">
                      <el-input v-model="ruleForm.dateTime" disabled></el-input>
                    </el-form-item>-->
          <el-form-item label="请选择班制：" prop="shiftSystem" style="">
            <el-radio-group v-model="ruleForm.shiftSystem">
              <el-radio v-for="value in workList" :key="value.id" :label="value.code" style="marginLeft: 20px;">
                {{ value.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <div>
            <div v-for="(v, index) in ruleForm.ShiftNameData" :key="index">
              <el-form-item :label="v.name + '：'" :prop="'ShiftNameData.' + index + '.model'" style=""
                            v-if="ruleForm.shiftSystem" :rules="{
      required: true, message: '请选择', trigger: 'change'
    }">
                <el-select v-model="v.model" placeholder="请选择班次" style="width: 100%;">
                  <el-option v-for="(f, findex) in LetClass" :label="f.name" :value="f.id" :key="findex"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="insertBtn">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {getWorkList, selectShiftName, getSeverLetClass, oneDayInsert} from "@/api/listClass";

export default {
  props: {
    workShop: String,
    // newValue: String,
    belongDate: String,
    newYear: Number,
    newMouth: Number,
    tipTime: Function,
    workshopName: String
  },
  data() {
    return {
      dialogVisible: false,
      year: '',
      mouth: '',
      newWorkShop: '',
      ruleForm: {
        workshop: '1',
        dateTime: '2020-12-12',
        shiftSystem: '',
        shiftSystemMorning: '',
        shiftSystemMiddle: '',
        shiftSystemEvening: '',
        shift: '',
        shift1: '123',
        ShiftNameData: [],
      },
      rules: {
        shiftSystem: [
          {required: true, message: '请选择', trigger: 'change'},
        ],
      },
      workList: [],
      ShiftNameData: [],
      LetClass: []
    }
  },
  created() {
    this.year = this.newYear;
    this.mouth = this.newMouth;
    getWorkList().then(res => {
      this.workList = res.data.data;
      this.ruleForm.dateTime = this.belongDate;
    });
    if (this.newWorkShop) {
      getSeverLetClass(this.newWorkShop).then((response) => {
        response.data.data.forEach((f) => {
          f.model = '';
        })
        this.LetClass = response.data.data
      })
    }
  },
  methods: {
    changeWorkShop(status, data) {
      this.dialogVisible = true;
      if (status == 0) { /*修改*/
        console.log(this.workList[0].id)
        if (data.length == 2) {
          this.ruleForm.shiftSystem = this.workList[0].code
        } else if (data.length == 3) {
          this.ruleForm.shiftSystem = String(this.workList[1].code)
        } else {
          this.ruleForm.shiftSystem = this.workList[2].code
        }
      }

    },
    handleClose() {
      this.dialogVisible = false;
    },
    insertBtn() {

      // var belongDate = this.ruleForm.belongDate;
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // alert('ok')
          var ruleForm = JSON.stringify(this.ruleForm);
          var num = 0;
          ruleForm = JSON.parse(ruleForm)
          var newDateTime = ruleForm.dateTime.split('-')


          var timeData = [];

          this.ruleForm.ShiftNameData.forEach((f) => {
            timeData.push({
              groupId: f.model, // 早中晚班
              shiftId: f.id,  //班制
            })
          });

          var data = {
            workshopId: this.workShop, // 车间ID
            belongDate: new Date(ruleForm.dateTime).getTime(), // 日期
            scheduleRequestList: timeData
          }

          console.log(data);

          oneDayInsert(data).then((response) => {
            this.dialogVisible = false;
            this.tipTime(newDateTime[0], newDateTime[1]);
          })
        } else {
          console.log('error submit!!');
          return false
        }
      });

    }
  },
  watch: {
    'ruleForm.shiftSystem'(New) {
      // console.log(New)
      var data = [];
      selectShiftName(New).then((response) => {
        data = response.data.data
        data.forEach((f, index) => {
          if (f.name == '轮班') {
            data.splice(index, 1);
          }
        })
        this.ShiftNameData = data;
        this.ruleForm.ShiftNameData = data;
      })
    },
    workShop(New) {
      this.newWorkShop = New
      getSeverLetClass(New).then((response) => {
        response.data.data.forEach((f) => {
          f.model = ''
        })
        this.LetClass = response.data.data
      })
    },
    belongDate(New) {
      this.ruleForm.dateTime = New;
    },
    newYear(New) {
      console.log(New)
      this.year = New
    },
    newMouth(New) {
      this.mouth = New;
    }
  }
}
</script>

