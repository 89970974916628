var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addTypesetting" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { marginTop: "50px" },
          attrs: {
            title: _vm.$t("Scheduling"),
            width: "80%",
            "append-to-body": true,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {},
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "font-size": "24px",
                    "font-weight": "500",
                    padding: "0 50px",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _c("div", {}, [
                    _c("p", { staticStyle: { color: "#009eff" } }, [
                      _vm._v(_vm._s(_vm.workshopName))
                    ])
                  ]),
                  _c("div", [_c("p", [_vm._v(_vm._s(_vm.ruleForm.dateTime))])])
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择班制：", prop: "shiftSystem" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.shiftSystem,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "shiftSystem", $$v)
                            },
                            expression: "ruleForm.shiftSystem"
                          }
                        },
                        _vm._l(_vm.workList, function(value) {
                          return _c(
                            "el-radio",
                            {
                              key: value.id,
                              staticStyle: { marginLeft: "20px" },
                              attrs: { label: value.code }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(value.name) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    _vm._l(_vm.ruleForm.ShiftNameData, function(v, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm.ruleForm.shiftSystem
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: v.name + "：",
                                    prop: "ShiftNameData." + index + ".model",
                                    rules: {
                                      required: true,
                                      message: "请选择",
                                      trigger: "change"
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请选择班次" },
                                      model: {
                                        value: v.model,
                                        callback: function($$v) {
                                          _vm.$set(v, "model", $$v)
                                        },
                                        expression: "v.model"
                                      }
                                    },
                                    _vm._l(_vm.LetClass, function(f, findex) {
                                      return _c("el-option", {
                                        key: findex,
                                        attrs: { label: f.name, value: f.id }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.insertBtn } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }