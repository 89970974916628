<template>
  <basic-container class="classSlort">
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose">
      <!-- 排班管理 cy 6.23-->
      <el-dialog
          title="新增"
          width="80%"
          :append-to-body="true">
        <el-form :model="itemForm_value" :rules="rules" ref="form">
          <p style="overflow: hidden;">
            <el-col :span="12">
              <el-form-item label="生产线:" :label-width="formLabelWidth" prop="wkln">
                <el-select v-model="itemForm_value.wkln" placeholder="请选择生产线" @change="chengeWkshp">
                  <el-option v-for="(item,index) in itemForm.des" :key="index" :label="item.des"
                             :value="item.cod"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车间:" :label-width="formLabelWidth" prop="workshop">
                <!-- <el-select v-model="itemForm_value.workshop" placeholder="请选择车间" @change = "chengeWorkshop"> -->
                <el-input v-model="itemForm_value.workshop" :disabled="true"></el-input>
                <!-- </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班次:" :label-width="formLabelWidth" prop="wkshft">
                <el-select v-model="itemForm_value.wkshft" placeholder="请选择班次" @change="chengeWkshft($event)">
                  <el-option v-for="(item,index) in itemForm.classes" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="起止时间:" :label-width="formLabelWidth" prop="btim">
                <el-time-picker
                    value-format="HH:mm:ss"
                    arrow-control
                    is-range
                    v-model="itemForm_value.btim"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="是否跨日:" :label-width="formLabelWidth" prop="cday">
                <el-select v-model="itemForm_value.cday" placeholder="请选择是否跨日">
                  <el-option v-for="(item,index) in cdayOption" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工作时间段:" :label-width="formLabelWidth" prop="etim" width='100%'>
                <el-date-picker
                    v-model="itemForm_value.etim"
                    width='100%'
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班组描述:" :label-width="formLabelWidth" prop="wktm">
                <el-select v-model="itemForm_value.wktm" placeholder="请选择班组描述">
                  <el-option :label="v.name" :value="v.id" v-for="(v,i) in itemForm.wktm" :key="i"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </p>
        </el-form>
        <p slot="footer" class="dialog-footer" style="margin-top: -30px;">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </p>
      </el-dialog>
      </el-form>
      <el-row>
        <el-button type="primary" icon="el-icon-plus"
                   @click="dialogVisible = true;itemForm_value={cday : 1,btim : ['00:00:00','00:00:00']}" style=""
                   v-if="permission.capacity_add">新增
        </el-button>
      </el-row>
      <el-calendar v-model="value" highlight="true">
        <template
            slot="dateCell"
            slot-scope="{date,data}">
          <div @click="calendarClick(data.day)" @mouseover="mouse(data.day)" @mouseout="mouseOut(data.day)"
               :style="`${teamData.includes(data.day) && formatTime(date,'yyyy-MM-dd') != formatTime(currentDate,'yyyy-MM-dd')?'height:160%;width:100%;backgroundColor:#FFFFD3;':''}` + `height: 100%;`"
               :class="(data.isSelected ? 'is-selected' : '') + (date < currentDate ? ' red' : '') + (formatTime(date,'yyyy-MM-dd') == formatTime(currentDate,'yyyy-MM-dd') ? ' green' : '')">
            {{ data.day.split('-').slice(1).join('-') }}
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click.stop="teamDelete(data)"
                       style="float: right;" :class="`${data.day==mouseDay?'enable':'disable'}`"
                       v-if="teamData.includes(data.day) && isShow(data.day,toDay)"></el-button>
            <el-button :class="`${data.day==mouseDay?'enable':'disable'}`" type="danger" icon="el-icon-edit" circle
                       size="mini" v-if="teamData.includes(data.day) && isShow(data.day,toDay)"
                       @click.stop="teamDialogChange(data)" style="float: right;"></el-button>
            <p v-html="capitalize(data.day,enableBtn)" style="fontSize:10px;line-height: 1.3rem;height:3rem;"></p>
          </div>
        </template>
      </el-calendar>
      <el-dialog width="80%" title="排班编辑" :visible.sync="teamDialog" append-to-body ref="crud">
        <el-form ref="editForm" :model="form" label-width="80px" :rules="rules">
          <p style="overflow: hidden;">
            <el-col :span="12">
              <el-form-item label="生产线" :label-width="formLabelWidth" prop="wkln">
                <el-select v-model="form.wkln" placeholder="请选择生产线" :disabled="true" @change="editchengeWkshp">
                  <el-option v-for="(item,index) in itemForm.des" :key="index" :label="item.des"
                             :value="item.cod"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="车间" :label-width="formLabelWidth">
                <el-input v-model="form.wkshpName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="工作时间段" :label-width="formLabelWidth" prop="etim" width='100%'>
                <el-date-picker
                    :disabled="true"
                    v-model="form.etim"
                    width='100%'
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          <div v-for="(item,i) in form.dates" :key="i">
            <el-col :span="6">
              <el-form-item :label-width="formLabelWidth" label="班组" prop="wktm">
                <el-select v-model="item.wktm" placeholder="请选择班组">
                  <el-option :label="v.name" :value="v.id" v-for="(v,i) in itemForm.wktm" :key="i"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班次:" :label-width="formLabelWidth">
                <el-select v-model="item.whshft" placeholder="请选择班次" @change="editwkshft($event,i)">
                  <el-option v-for="(item,index) in itemForm.classes" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="起止时间" :label-width="formLabelWidth" prop="btim">
                <el-time-picker
                    is-range
                    v-model="item.btim"
                    arrow-control
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="HH:mm:ss"
                    placeholder="选择时间范围">
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="是否跨日:" :label-width="formLabelWidth" prop="cday">
                <el-select v-model="item.cday" placeholder="请选择是否跨日" @change="chengeCday($event)">
                  <el-option v-for="(item,index) in cdayOption" :key="index" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>
          <el-col :span="12">
            <el-form-item :label-width="formLabelWidth" label="创建人">
              <el-input v-model="form.createUserName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label-width="formLabelWidth" label="创建时间">
              <el-date-picker
                  v-model="form.createTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  :disabled="true"
                  default-time="12:00:00 00:00">
              </el-date-picker>
            </el-form-item>
          </el-col>
          </p>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button v-if="permission.capacity_edit" @click="teamDialog = false">取 消</el-button>
        <el-button v-if="permission.capacity_edit" type="primary" @click="resConfirm('edit')">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>
<script>
/* eslint-disable */
import {dateFormat} from '@/util/date'
import {mapGetters} from "vuex";
import {list} from "@/api/listClass"

export default {
  // watch: {
  //   value(newval,oldval){
  //     // alert(this.value);
  //     list(dateFormat(newval).split("-")[0],dateFormat(newval).split("-")[1]).then(res => {
  //       for (let key in res.data.data) {
  //       }
  //       this.enableBtn = res.data.data
  //       //alert(Object.keys(res.data.data)[0])
  //       //alert(Object.keys(res.data.data)[1])
  //       //alert(Object.keys(res.data.data)[2])
  //     });
  //     //list(newval.getFullYear(),newval.getMonth() + 1).then();
  //     //alert(newval.getFullYear() + "-" + (newval.getMonth() + 1) + "-" + newval.getDate());
  //     console.log('debugger',dateFormat(newval));
  //     // console.log(dateFormat(newval))
  //     // console.log(newval);
  //     // console.log(dateFormat(newval).split("-")[1],dateFormat(newval).split("-")[0]);
  //     this.formInline.month = dateFormat(newval).split("-")[1];
  //     this.formInline.year = dateFormat(newval).split("-")[0];
  //   }
  // },
  computed: {
    isShow: {
      get() {
        return mapGetters(['permission.dict_add'])
      },
    }
    // currentDate() {
    //   let time = new Date();
    //   let date = new Date(1900 + time.getYear(), time.getMonth(), time.getDate());
    //   console.log(date)
    //   return time;
    // }
  },
  // directives:{dialogDrag},
  filters: {
    capitalize: (day, enable) => {
      let text = ''
      let time = ''
      for (let key in enable) {
        day == key ? enable[key].forEach(v => {
          text += "<p>" + v.shiftName + "&nbsp" + v.shiftType + "</p>"
        }) : "";
      }
      return text;
      //enable.forEach((ele,i)=> {
      //if(ele.data == day) {
      //time += ('<p>' + ele.text + "    " + ele.time + '</p>')
      //text = ele.text
      //console.log(text);
      //}
      //})
      // return text +"    " + time
      return time
    },
    // isShow : (data,value) => {
    //     //是否大于当前时间
    //     if (Date.parse(data.replace(/|-/g,'')) > Date.parse(value.replace(/|-/g,''))) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }
  },
  data() {
    return {
      cdayOption: [{name: "是", id: 1}, {name: "否", id: 0}],
      toDay: "2021-1-21",
      styleObj: {height: "100%", width: "100%", backgroundColor: "green"},
      optionsRes: [{label: '白班', value: 1}, {label: '夜班', value: 0}],
      arr: [],
      enableBtn: {"2020-7-05": [{departmentName: "测试2", shiftName: "早两", shiftType: "两班制"}], "2021-1-27": []},
      mouseDay: "2021-01-13",
      isMouse: true,
      teamTime: [],
      teamDialog: false,
      wktmName: '测试班组',
      teamData: ['2021-01', '2020-07', '2020-07'],
      value: "2021-01-22",
      calendarRange: ["2020-07-01", "2020-07-31"],
      formInline: {
        wkln: '',
        workshop: '',
        wkshft: '',
        start_time: '',
        wkday: '',
        year: '2020',
        month: '',
        wktm: '',
        time: ''
      },
      rules: {
        wkln: [
          {required: true, message: '请输入生产线:', trigger: 'blur'}
        ],
        workshop: [
          {required: true, message: '请输入车间:', trigger: 'blur'}
        ],
        wkshft: [
          {required: true, message: '请选择班次', trigger: 'blur'}
        ],
        btim: [
          {required: true, message: '请输入起止时间:', trigger: 'blur'}
        ],
        etim: [
          {required: true, message: '请输入工作时间段', trigger: 'blur'}
        ],
        wktm: [
          {required: true, message: '请输入班组描述', trigger: 'blur'}
        ],
        time: [
          {required: true, message: '请输入起止时间', trigger: 'blur'}
        ],
        createUserName: [
          {required: true, message: '请输入创建人', trigger: 'blur'}
        ],
      },
      value1: '',
      dialogVisible: false,
      itemDialog: false,
      itemForm_value: {
        cday: 1,
        wkln: '',
        etim: '',
        wkshft: [],
        btim: [new Date(new Date().toLocaleDateString()).getTime(),
          new Date(new Date().toLocaleDateString()).getTime() + ((24 * 60 * 60 * 1000) - 1)],
        wkday: '',
        wktm: '',
        time: ''
      },
      itemForm: {
        des: [],
        workshop: [],
        classes: [],
        wktm: [],
        updatedTime: '',
        btim: [],
        etim: [1, 2, 3]
      },
      materielForm: {},
      seriesForm: {},
      formLabelWidth: "120px",
      itemDialogTitle: "新增定性项目",
      materiel: false,
      series: false,
      form: {
        wkln: '',
        etim: [new Date(new Date().toLocaleDateString()).getTime(),
          new Date(new Date().toLocaleDateString()).getTime() + ((24 * 60 * 60 * 1000) - 1)],
        wkshft: [],
        btim: ['11:00:00', '12:00:00'],
        wkday: '',
        wktm: '',
        time: ''
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        dialogWidth: "60%",
        border: true,
        index: true,
        viewBtn: true,
        editBtn: true,
        addBtn: false,
        delBtn: true,
        selection: true,
        menuWidth: 120,
        menuPosition: "left",
        column: [
          {
            label: "工厂",
            prop: "gc",
            hide: true,
            search: true,
            type: "select",
            dicUrl: this.basisRoot + "basisPlant/selUserAllPlant ",
            props: {
              label: "des",
              value: "cod"
            },
            rules: [
              {
                required: true,
                message: "请输入工厂",
                trigger: "blur"
              }
            ],
            addDisplay: false,
            editDisplay: false
          },
          {
            label: "工序",
            prop: "wkshp",
            dicUrl: this.lbrRoot + "downDrop/wkpcs",
            props: {
              label: "name",
              value: "id"
            },
          },
          {
            label: "物料编码",
            prop: "wlbm",
            hide: true,
            search: true
          },
          {
            label: "物料描述",
            prop: "wlms",
            editDisplay: false,
            addDisplay: false,
            search: true
          },
          {
            label: "物料系列编码",
            prop: "wlxlbm",
            searchLabelWidth: 108,
            hide: true,
            search: true
          },
          {
            label: "物料系列描述",
            prop: "wlxlms",
            searchLabelWidth: 138,
            editDisplay: false,
            addDisplay: false,
            search: true
          },
          {
            label: "定性项目检验类型",
            prop: "dxxmjylx"
          },
          {
            label: "重要度",
            prop: "zyd",
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          },
          {
            label: "定性项目描述",
            prop: "dxxmms"
          },
          {
            label: "技术要求",
            prop: "jsyq"
          },
          {
            label: "启用标识",
            prop: "qybs",
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          },
          {
            label: "顺序号",
            prop: "sxh"
          },
          {
            label: "检测设备",
            prop: "jcsb"
          },
          {
            label: "检测类型",
            prop: "jclx",
            hide: true,
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          },
          {
            label: "是否适用",
            prop: "sfsy"
          },
          {
            label: "人为干扰易发项",
            prop: "rwgryfx"
          },
          {
            label: "创建人",
            prop: "createUserName",
            editDisplay: false,
            addDisplay: false
          },
          {
            label: "创建时间",
            type: 'createTime',
            //   timestamp
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            prop: "cjsj",
            editDisplay: false,
            addDisplay: false
          },
          {
            label: "最后修改人",
            prop: "updateUserName",
            editDisplay: false,
            addDisplay: false
          },
          {
            label: "最后修改时间",
            prop: "updateTime",
            type: 'updateTime',
            //   timestamp
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            editDisplay: false,
            addDisplay: false
          }
        ]
      },
      materielOption: {
        tip: false,
        dialogWidth: "60%",
        border: true,
        index: true,
        menu: false,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        menuWidth: 120,
        menuPosition: "left",

        column: [
          {
            label: "物料ERP编码",
            prop: "ERP",
            search: true
          },
          {
            label: "物料描述",
            prop: "wlms",
            search: true
          },
          {
            label: "物料规格",
            prop: "wlgg",
            search: true
          },
          {
            label: "公司",
            prop: "gs"
          },
          {
            label: "工厂",
            prop: "gc",
            hide: true,
            search: true,
            type: "tree",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          },
          {
            label: "计量单位",
            prop: "jldw"
          },
          {
            label: "物料组",
            prop: "wlz",
            hide: true,
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          },
          {
            label: "物料类型",
            prop: "wllx",
            hide: true,
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          }
        ]
      },
      seriesOption: {
        tip: false,
        dialogWidth: "60%",
        border: true,
        index: true,
        menu: false,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        menuWidth: 120,
        menuPosition: "left",
        column: [
          {
            label: "物料系列编码",
            prop: "xlxlbm",
            search: true
          },
          {
            label: "物料系列描述",
            prop: "wlxlms",
            search: true
          },
          {
            label: "公司",
            prop: "gs"
          },
          {
            label: "工厂",
            prop: "gc",
            hide: true,
            search: true,
            type: "select",
            dicUrl: "/",
            props: {
              label: "",
              value: ""
            }
          }
        ]
      },
      data: [],
      materielData: [],
      seriesData: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false)
      };
    }
  },
  created() {
    let time = new Date();
    this.currentDate = new Date(1900 + time.getYear(), time.getMonth(), time.getDate());
    this.formInline.month = dateFormat(new Date(), "MM");
    this.toDay = dateFormat(new Date());
    this.value = dateFormat(new Date());
    list(this.value.split("-")[0], this.value.split("-")[1]).then(res => {
      for (let key in res.data.data) {
      }
      this.enableBtn = res.data.data
      //alert(Object.keys(res.data.data)[0])
      //alert(Object.keys(res.data.data)[1])
      //alert(Object.keys(res.data.data)[2])
    });
    // console.log(Vue.prototype);
    //---------------------------------------------------------------------------------------------------------------------------------
    // select_list().then((res)=> {
    //   this.itemForm.des = res.data.data;
    //   this.formInline.wkln = this.itemForm.des[0].cod;
    // });
  },
  methods: {
    getEmit(data) {

    },
    handleClose() {

    },
    calendarClick(day) {
      this.$emit("monDay", 0, day);
    },
    capitalize: (day, enable) => {
      let text = "";
      let time = ''
      for (let key in enable) {
        if (day == key) {
          console.log(enable[Object.keys(enable)[0]]);
          enable[key][0] ? text += "<p>" + enable[key][0].shiftType + "</p>" : "";

          enable[key].forEach(v => {
            console.log(v);
            text += "<p>" + v.shiftName + "&nbsp" + v.departmentName + "</p>"
          });
        }
      }
      console.log(text);
      return text;
      //enable.forEach((ele,i)=> {
      //if(ele.data == day) {
      //time += ('<p>' + ele.text + "    " + ele.time + '</p>')
      //text = ele.text
      //console.log(text);
      //}
      //})
      // return text +"    " + time
      return time
    },
    chengeCday(event) {
      console.log('debugger', event, this.form)
      this.$set(this.form, this.form.cday, event);
      this.$forceUpdate();
      // this.setState({
      //   cday : event.target.value,
      // });
    },
    formatTime(date, fmt) {
      var o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((date.getMonth() + 3) / 3),
        "S": date.getMilliseconds()
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    },
    // 班次
    editwkshft(event, i) {
      // 起止时间
      wktmList(event, 1, 1000).then(res => {
        this.form.dates[i].btim = [res.data.data.records[0].btim, res.data.data.records[0].etim]
        this.form.dates[i].cday = res.data.data.records[0].cday;
        this.$set(this.form, this.form.cday, res.data.data.records[0].cday);
        this.$forceUpdate();
      })
    },
    isShow(data, value) {
      //是否大于当前时间
      if (Date.parse(data.replace(/|-/g, '')) >= Date.parse(value.replace(/|-/g, ''))) {
        return true;
      } else {
        return false;
      }
    },
    // wkshpSelect
    chengeWkshft(event) {
      wktmList(event, 1, 1000).then(res => {
        this.itemForm_value.btim = [res.data.data.records[0].btim, res.data.data.records[0].etim]
        this.itemForm_value.cday = res.data.data.records[0].cday;
        this.$set(this.itemForm_value, this.form.cday, res.data.data.records[0].cday);
        this.$forceUpdate();
      })
    },
    toSubmit(form) {
      let btim = form.btim[0] + " " + form.etim[0]
      let etim = form.btim[1] + " " + form.etim[1]
      form.btim = btim
      form.etim = etim
      return form
    },
    // edit
    resConfirm(type) {
      let wkshftList = []
      this.form.dates.forEach(v => {
        // 班次对象
        wkshftList.push({
          "id": +v.whshft,
          "beginTime": v.btim[0],
          "endTime": v.btim[1],
          "wktm": v.wktm,
          "cday": v.cday
        })
      })
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          console.log(valid)
          let src = {
            id: this.form.id,
            "wkln": this.form.wkln,
            "wkshftList": wkshftList,
            "wktm": this.form.wktm,
            "beginWorkDya": this.form.etim[0],
            "endWorkDya": this.form.etim[1]
          }
          type === "edit" ? editCapacity(src).then(res => {
                this.$message({type: 'success', message: '操作成功'});
                this.teamDialog = false;
                lbr_list(this.formInline).then(res => {
                  this.teamData = [];
                  this.enableBtn = [];
                  this.lbrForm = res.data.data
                  this.value = this.formInline.year + '-' + this.formInline.month + '-' + "01"
                  res.data.data.forEach(element => {
                    this.teamData.push(element.btim.split(' ')[0])
                    this.enableBtn.push({
                      time: element.btim.split(' ')[1] + "-" + element.etim.split(' ')[1],
                      text: element.wktmName,
                      data: element.btim.split(' ')[0]
                    })
                  })
                })
              }) :
              select_submit(src).then(res => {
                this.$message({type: 'success', message: '操作成功'});
                lbr_list(this.formInline).then(res => {
                  this.lbrForm = res.data.data
                  this.value = this.formInline.year + '-' + this.formInline.month + '-' + "01"
                  this.teamData = []
                  res.data.data.forEach(element => {
                    this.teamData.push(element.btim.split(' ')[0])
                    this.enableBtn.push({
                      time: element.btim.split(' ')[1] + "-" + element.etim.split(' ')[1],
                      text: element.wktmName,
                      data: element.btim.split(' ')[0]
                    })
                  })
                })
              });
          //   let timeBtim = this.itemForm_value.etim[0]+' '+this.itemForm_value.btim[0]
          //   let timeEtim = this.itemForm_value.etim[1]+' '+this.itemForm_value.btim[1]
          //   this.itemForm_value.btim = timeBtim;this.itemForm_value.etim = timeEtim;
          //   select_submit(this.itemForm_value).then((res) => {
          //     // console.log(res)
          //   })
          //   this.dialogVisible = false
        } else {
          return false;
        }
      });
      // console.log(this.toSubmit(this.form));editCapacity(this.form).then(res=>{this.$message({type:'success',message:'操作成功'})});this.teamDialog = false;this.form={}
    },
    teamDelete(data) {
      if (!this.lbrForm[0].data) {
        this.lbrForm.forEach(e => {
          e.data = e.btim.split(' ')[0];
        })
      }
      let editDay = this.lbrForm.filter(res => {
        return res.data == data.day
      });
      let ids = '';
      editDay.forEach(v => {
        ids += v.id + ",";
      })
      console.log('--------------', editDay, ids)
      this.$confirm("确定要清空该条排班吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        return romveCapacity(ids);
      }).then(() => {
        this.search();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      })
    },
    teamDialogChange(data) {
      // this.form.cday = 1;
      if (this.lbrForm) {
        // 存入data
        if (!this.lbrForm[0].data) {
          this.lbrForm.forEach(e => {
            e.data = e.btim.split(' ')[0];
          })
        }
        let editDay = this.lbrForm.find(res => {
          return res.data == data.day
        });
        console.log(editDay);
        this.teamDialog = true;
        console.log(this.form, editDay, data);
        this.form = editDay
        this.form.btim = [editDay.btim.split(" ")[1], editDay.etim.split(" ")[1]]
        this.form.wkln = editDay.wkln
        this.form.whshft = editDay.whshft
        this.form.etim = [data.day, data.day]
        this.form.createUserName = editDay.createUserName
        this.form.createTime = editDay.createTime
        this.form.wkshpCod = editDay.wkshpCod
        this.form.wkshftName = editDay.wkshftName
        // 班组
        this.form.id = editDay.id
        // 获取是否跨日
        wktmList(this.form.whshft, 1, 1000).then(res => {
          this.form.btim = [res.data.data.records[0].btim, res.data.data.records[0].etim]
          this.form.cday = res.data.data.records[0].cday
        })
        this.$forceUpdate();
        // select_lbrwktmsch(editDay.wkln).then(res=>{this.itemForm.classes = res.data.data})
      } else {
        this.teamDialog = true;
      }
    },//编辑
    mouseOut(scope) {
      this.mouseDay = scope;
      this.isMouse = false
    },
    mouse(scope) {
      this.mouseDay = scope;
      this.isMouse = true
    },
    beforeOpen() {
    },
    searchChange() {
    },
    searchReset() {
    },
    currentChange() {
    },
    sizeChange() {
    },
    onLoad() {
    },
    rowUpdate() {
    },
    rowSave() {
    },
    rowDel() {
    },
    click() {
    },
    res(val) {
      val = val.map(String).join(',')
      wkshpTime(val).then(res => {
        if (res.data.data) {
          this.itemForm.etim = []
          res.data.data.forEach(element => {
            element.btim = element.btim.split(' ')[1]
            this.itemForm.etim.push(element.btim + '-' + element.etim)
          });
        }
      })
    },
    editchengeWkshp(val) {
      if (this.itemForm.des) {
        this.itemForm_value.workshop = this.itemForm.des.find(res => {
          return res.cod === val
        }).wkshpDes
      } else {
        this.$message({
          type: 'success',
          message: '请刷新'
        })
      }
      let wkln = this.itemForm.des.find((v) => {
        return v.cod == val
      })
      // 车间/产线
      select_Wkshp(wkln.wkshp, val).then((res) => {
        this.form.workshop = res.data.data
      })
      select_lbrwktmsch(val).then((res) => {
        this.form.classes = res.data.data
      })
      select_wktm(val).then((res) => {
        this.form.wktm = res.data.data
      })
    },
    chengeWkshp(val) {
      if (this.itemForm.des) {
        this.itemForm_value.workshop = this.itemForm.des.find(res => {
          return res.cod === val
        }).wkshpDes
      } else {
        this.$message({
          type: 'success',
          message: '请刷新'
        })
      }
      let wkln = this.itemForm.des.find((v) => {
        return v.cod == val
      })
      select_Wkshp(wkln.wkshp, val).then((res) => {
        this.itemForm.workshop = res.data.data
      })
      select_lbrwktmsch(val).then((res) => {
        this.itemForm.classes = res.data.data
      })
      select_wktm(val).then((res) => {
        this.itemForm.wktm = res.data.data
      })
    },
    chengeWorkshop() {
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let src = {
            "wkln": this.itemForm_value.wkln,
            "wkshftList": [
              {
                "id": this.itemForm_value.wkshft,
                "beginTime": this.itemForm_value.btim[0],
                "endTime": this.itemForm_value.btim[1],
                "wktm": this.itemForm_value.wktm,
                "cday": this.itemForm_value.cday
              }
            ],
            "wktm": this.itemForm_value.wktm,
            "beginWorkDya": this.itemForm_value.etim[0],
            "endWorkDya": this.itemForm_value.etim[1]
          }
          select_submit(src).then((res) => {
            this.$message({type: 'success', message: '操作成功'});
            lbr_list(this.formInline).then(res => {
              this.lbrForm = res.data.data
              this.value = this.formInline.year + '-' + this.formInline.month + '-' + "01"
              this.teamData = [];
              this.enableBtn = [];
              res.data.data.forEach(element => {
                this.teamData.push(element.btim.split(' ')[0])
                this.enableBtn.push({
                  time: element.btim.split(' ')[1] + "-" + element.etim.split(' ')[1],
                  text: element.wktmName,
                  data: element.btim.split(' ')[0]
                })
              })
            })
          })
          this.dialogVisible = false
        } else {
          return false;
        }
      });
    },
    search() {
      this.value = this.formInline.year + "-" + this.formInline.month + "-" + 1;
      list(this.value.split("-")[0], this.value.split("-")[1]).then(res => {
        for (let key in res.data.data) {
        }
        this.enableBtn = res.data.data
        //alert(Object.keys(res.data.data)[0])
        //alert(Object.keys(res.data.data)[1])
        //alert(Object.keys(res.data.data)[2])
      });
      select_lbrwktmsch(this.formInline.wkln).then((res) => {
        this.itemForm.classes = res.data.data
      })
      select_wktm(this.formInline.wkln).then((res) => {
        this.itemForm.wktm = res.data.data
      })
    }
  }
};
</script>

<style lang="scss" type="text/scss" scoped>
.classSlort {
  /* .el-calendar >>> .el-calendar-table td.is-selected {
    background-color: green !important;
} */
  .w220 {
    width: 220px;
  }

  /* .demo-form-inline.el-form--inline .el-form-item:nth-child(4){float: right;} */
  .demo-form-inline {
    /* margin-left: 20%; */
  }

  .el-select {
    width: 100%;
  }

  .block /deep/ .el-input__inner {
    width: 100%
  }

  .el-form-item__content /deep/ .el-input__inner {
    width: 100%
  }

  p {
    font-size: 2px;
  }

  .disable {
    display: none;
  }

  .enable {
    display: block;
  }

  .el-form-item > > > .el-date-editor {
    width: 100%;
  }

  .el-calendar /deep/ .el-calendar-table td {
    background-color: rgb(255, 255, 255);
    height: 70px;
  }

  /deep/ .el-calendar-table td {
    background-color: #fff;
    height: 70px;
  }

  /deep/ .el-calendar-day {
    height: 110px;
  }

  .el-calendar > > > .el-calendar-table__row .next {
    /* background-color: #F7F7F7; */
  }

  .el-calendar > > > .el-calendar-table__row .prev {
    /* background-color: #F7F7F7; */
  }

  .el-calendar > > > .el-calendar__body .is-today {
    padding: 8px;
    padding-bottom: 15px;
  }

  .el-calendar > > > .el-calendar__body .is-today > .el-calendar-day {
    //background: #61C0A0;
    height: 69px;
    padding: 0px;
    color: black;
  }

  .red {
    background: #F7F7F7 !important;
    // line-height: 35px;
    /* color: #C0C4CC; */
    height: 100%;
  }

  /deep/ .el-date-editor.el-input, .el-date-editor--daterange.el-input__inner {
    width: 100% !important;
  }

  .el-form.demo-form-inline.el-form--inline /deep/ .el-input__icon {
    display: none;
  }

  /deep/ .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
  }

  .green {
    background: #909399;
    height: 100%;
    color: black;
  }

  .el-popper {
    display: none;
  }

  .el-time-range-picker.el-picker-panel.el-popper {
    display: none;
  }
}

</style>
<style scoped>.el-picker-panel.el-date-picker.el-popper /deep/ .el-date-picker__header {
  display: none !important;
}</style>
